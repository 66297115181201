import React from 'react'
import clsx from 'clsx'
import { Link as RemixLink } from '@remix-run/react'
import { LinkPillProps } from './link-pill'

export type { LinkPillProps }

export const LinkPill: React.FC<LinkPillProps> = ({
  className,
  icon,
  rounded = false,
  color = 'primary',
  children,
  onClick,
  ...props
}) => {
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        onClick(e)
      } else {
        // e.preventDefault()
        e.stopPropagation()
      }
    },
    [onClick],
  )
  let bg = 'bg-primary-100 hover:bg-primary-100 disabled:bg-primary-50'
  let text = 'text-primary-500 disabled:text-primary-400'
  // if (checked) {
  //   bg = 'bg-primary-600'
  //   text = 'text-primary-contrast'
  // }
  const classes = clsx(
    'text-center shadow-sm flex justify-center items-center flex-nowrap shrink-0 cursor-pointer disabled:cursor-default',
    'px-2.5 py-0.5 my-1 mx-1 font-medium text-xs rounder-full max-w-64 inline-block',
    {
      'bg-primary-600 hover:bg-primary-700 disabled:bg-primary-200':
        color === 'primary',
      'bg-secondary-600 hover:bg-secondary-700 disabled:bg-secondary-200':
        color === 'secondary',
      'bg-highlight-600 hover:bg-highlight-700 disabled:bg-highlight-200':
        color === 'highlight',
      'bg-warn-600 hover:bg-warn-700 disabled:bg-warn-200': color === 'warn',
      'bg-error-600 hover:bg-error-700 disabled:bg-error-200':
        color === 'error',
    },
    text,
    className,
  )
  return (
    <RemixLink
      className={clsx(
        'inline-flex items-center bg-primary-500 border border-transparent font-semibold',
        'text-white hover:bg-primary-600 focus:outline-none focus:ring focus:border-primary-700 active:bg-primary-800',
        { 'rounded-md': !rounded, 'rounded-lg': !!rounded },
        classes,
      )}
      role="link"
      onClick={handleClick}
      {...props}
    >
      {icon ? (
        <span className={clsx('absolute inset-y-1.5 left-2.5', text)}>{icon}</span>
      ) : null}
      <span
        className={clsx('whitespace-nowrap overflow-hidden text-ellipsis', {
          'pl-4': !!icon,
        })}
      >
        {children}
      </span>
    </RemixLink>
  )
}
